<template>
  <div class="home">
    <!-- logo 搜索框  start -->
    <goods-search @search="onSearch" :name="searchValue"/>
    <!-- logo 搜索框  end -->

    <!--    列表容器 start    -->
    <div class="list-container" id="list-container" v-infinite-scroll="onLoadMore"
         :infinite-scroll-disabled="disabledScroll"
         infinite-scroll-immediate="false">
      <a-spin v-if="searchLoading" :spinning="searchLoading" tip="正在加载..."/>
      <!--      列表项-->
      <div v-else-if="dataList && dataList.length>0" @click="onShowQrCode">

        <!-- 产品卡片 start -->
        <goods-card v-for="(item,index) in dataList" :key="index" :item="item"/>
        <!-- 产品卡片 end -->

        <p v-if="loadmore">加载中...</p>
        <p v-if="noMore">没有更多了</p>
      </div>

      <div v-else class="typeface">
        暂无数据
      </div>
    </div>
    <!--    列表容器  end  -->

    <!-- 微信二维码弹框 start -->
    <a-modal :visible="showQrCode" width="500px" :footer="null" centered :closable="false" @cancel="onHideQrCode">
      <el-image
          :src="require('@/assets/ichelps.jpg')"
      >
      </el-image>
    </a-modal>
    <!-- 微信二维码弹框 end -->

  </div>
</template>
<script>
// Modal
import {message} from 'ant-design-vue';
import {fetch} from "@/util/request";
import GoodsCard from "./component/GoodsCard";
import GoodsSearch from "./component/GoodsSearch";

export default {
  components: {GoodsSearch, GoodsCard},
  mounted() {
    // this.searchValue = this.$route.query.keyword
console.log(this.$route.query.keyword)
    this.onSearch(this.$route.query.keyword)
  },

  data() {
    return {
      // 搜索框的值
      searchValue: '',
      // 搜索加载状态
      searchLoading: false,
      dataList: [],
      pageInfo: {
        page: 1, // 起始页
        size: 20, // 每页的数量
        totalPages: 0, // 去后端返回内容的总页数
      },
      loadmore: false,
      // 是否显示二维码弹窗
      showQrCode: false, // 小程序二维码
    };
  },
  computed: {
    // eslint-disable-next-line
    noMore() {
      // 当起始页数大于总页数时停止加载
      return this.pageInfo.page >= this.pageInfo.totalPages;
    },
    disabledScroll() {
      return this.loading || this.noMore;
    }
  },
  methods: {
    // 点击查询  value
    onSearch(value) {
      console.log('value',value)
      this.searchValue = value
      //   Modal.warning({
      //           title: () => '提示信息',
      //           content: () => '系统正在维护，该功能暂时关闭',
      //         })
      //         return

      if (this.searchLoading) {
        return;
      }
      if (!value) {
        message.error('请输入关键字')
        return
      }
      this.dataList = []
      this.searchLoading = true
      fetch({
        goodsSn: this.searchValue,
        page: 1,
        pageSize: this.pageInfo.size
      }).then(res => {
        const {records = [], pages, size, current} = res.data.data || {}

        this.dataList = records
        this.pageInfo = {
          page: current,
          size,
          totalPages: pages
        }
        this.searchLoading = false
      }).catch(() => {
        this.searchLoading = false
      })
    },
    // 滚动到底部触发
    onLoadMore() {
      this.loadmore = true;
      fetch({
        goodsSn: this.searchValue,
        page: this.pageInfo.page + 1,
        pageSize: this.pageInfo.size
      }).then(res => {

        const {records = [], pages, size, current} = res.data.data || {}

        this.dataList = this.dataList.concat(records)
        this.pageInfo = {
          page: current,
          size,
          totalPages: pages
        }
        console.log('下滑获取后端的数据', res)

        console.log('后端返回的总条数', this.totalPages)
        this.loadmore = false
      }).catch(err => {
        console.log(err)
        this.loadmore = false
      });
    },

    // 显示二维码弹窗
    onShowQrCode() {
      this.showQrCode = true
    },
    onHideQrCode() {
      this.showQrCode = false
    },

  },
};

</script>
<style lang="less" scoped>

.logo {
  z-index: 999;
  margin-top: 114px;
}

.ant-divider-horizontal {
  margin: 18px 0px 1px;
}

.typeface {
  margin-top: 260px;
  block-size: 0px;
  font-size: 27px;
  color: rgb(0 0 0 / 45%);
}


.list-item-row1 {
  width: 8px;
  height: 11px;
  background: #ff0000;
  margin-right: 5px;
}


.list-militarySn {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  background: #efefef;
  color: #40a9ff;
}

.list-detail {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  background: #efefef;
  border-radius: 3px;
  padding: 5px;
}


.ant-avatar {
  border-radius: 0 !important;
}

a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
  width: 597px;
}

.ant-list-item {
  flex-direction: column;
  height: 198px;
}

.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 564px;
}

.demo-loadmore-list {
  background: #FFF;
  width: 1100px;
  height: 683px;
  margin: 20px auto !important;
  flex: 1;
  overflow: auto;
}

.ant-spin-nested-loading {
  position: relative;
  margin: 70px auto;
}

.ant-spin {
  max-height: none !important;
  margin-top: 10px;
}


.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 4px;
  width: 1360px;
}

.ant-collapse-content {
  border-radius: 0 0 4px 4px;
  height: 816px;
}

.ant-input-group.ant-input-group-compact > *:last-child {
  border-right-width: 0 !important;
  //margin-top: 105px;
}

.home {
  height: 100vh;
  position: relative;
  overflow: hidden;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .list-container {
    height: 644px;
    width: 600px;
    background: #eaeaea;
    overflow: auto;
    padding: 0 10px;
    margin-top: 22px;
  }
}

</style>
