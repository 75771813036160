<template>
  <div class="list-item">
    <div v-if="item.goodsSn" class="list-item__title">
      {{ item.goodsSn }}
    </div>
    <div style="display: flex;background: #efefef; ">
      <div class="list-entirety">
        <div class="list-item__tag--blue" v-if="item.militarySn"></div>
        {{ item.militarySn }}
      </div>
      <div class="list-entirety">
        <div class="list-item__tag--brown" v-if="item.packageType"></div>
        <!--封装-->{{ item.packageType }}
      </div>
      <div class="list-entirety">
        <div class="list-item__tag--green" v-if="item.grade"></div>
        {{ item.grade }}
      </div>
    </div>
    <div class="list-head">
      <div class="list-brand" v-if="item.brandName">
        <!--品牌-->{{ item.brandName }}
      </div>
      <div class="list-introductionUrl" v-if="item.introductionUrl">
        <img src="@/assets/pdf.png" style="width: 27px"> <a> 数据手册</a>
      </div>
      <div></div>
    </div>

    <div class="list-item-row" v-if="item.brief" style="border-top: 1px solid #ededed">
      {{ item.brief }}x
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsCard",
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style lang="less" scoped>
// 类__属性--样式

.list-entirety {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}

.list-item {
  background: #fff;
  margin: 10px 0;
  padding: 10px 20px;

  .list-item__title {
    color: #1f1f1f;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
  }

  .list-item__tag {
    width: 5px;
    height: 5px;
    border-radius: 50px;
    margin-right: 5px;
    background: blue;
  }


  .list-item__tag--blue {
    .list-item__tag;
    background: blue;
  }

  .list-item__tag--brown {
    .list-item__tag;
    background: #d7754f;
  }

  .list-item__tag--green {
    .list-item__tag;
    background: #15dc4d;
  }

}


.list-head {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 10px;
  position: relative;
}

.list-brand {
  font-size: 20px;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  padding: 2px 10px;
}

.list-introductionUrl {
  font-size: 15px;
  border: 1px solid #467ebf;
  border-radius: 3px;
  padding: 2px 10px;
}

.list-item-row {
  text-align: left;
  display: flex;
  align-items: baseline;
  padding-top: 5px;
}



</style>