<template>
  <div class="logo ">
    <div>
      <img src="@/assets/logo1.png"/>
    </div>
    <a-input-search v-model="goodsSn" placeholder="请输入芯片型号" enter-button="查询一下" size="large" style="width: 600px"
                    @search="onSearch" />

  </div>

</template>

<script>
export default {
  name: "GoodsSearch",
  components: {},
  props:{
    name:{
      type:String,
      default:''
    }
  },
  data(){
    return{
      goodsSn:''
    }
  },
  watch:{
    name:{
      handler(newData){
        console.log('handler',newData)
        this.goodsSn = newData
      },
      immediate:true
    }
  },
  methods:{
    onSearch(){
      this.$emit('search',this.goodsSn)
    }
  }
}
</script>

<style scoped>

</style>